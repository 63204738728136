/*******************************************************************************

  Project: Good Start
  Author: XXX
  Date: XX.XXXX

********************************************************************************

  1. Scroll to top
  2. Off canvas menu
  3. EU Cookie
  4. Dropdown Menu
  5. Lightbox for images and videos
  6. Responsive Videos
  7. Scoll Animations Init
  8. Object Fit Images
  9. Framework Scripts
  10. Contact Form 7 Fix
  11. Accordion
  12. Map
  13. Equal Heights
  14. Smooth Scroll

*******************************************************************************/

jQuery( document ).ready(function($) {

/* 1. Scroll to top
------------------------------------------------------------------------------*/

  $(document).on( 'scroll', function(){
    if ($(window).scrollTop() > 200) {
      $('.o-scroll-to-top').addClass('show');
    } else {
      $('.o-scroll-to-top').removeClass('show');
    }
  });

  $('.o-scroll-to-top').on('click', function(event) {
    event.preventDefault();
    var verticalOffset = typeof(verticalOffset) != 'undefined' ? verticalOffset : 0;
    var element = $('body');
    var offset = element.offset();
    var offsetTop = offset.top;
    $('html, body').animate({scrollTop: offsetTop}, 500, 'linear');
  });

/* 2. Off canvas menu
------------------------------------------------------------------------------*/

  $('.toggle-nav').click(function() {
    // Calling a function in case you want to expand upon this.
    toggleNav();
  });

  function toggleNav() {
    if ($('#site-wrapper').hasClass('show-nav')) {
      // Do things on Nav Close
      $('#site-wrapper').removeClass('show-nav');
    } else {
      // Do things on Nav Open
      $('#site-wrapper').addClass('show-nav');
    }
  }

  // Fuction for hiding mobile navigation
  function hideNav() {
    if ($('#site-wrapper').hasClass('show-nav')) {
      $('#site-wrapper').removeClass('show-nav');
    }
  }

  // Hide navigation when user pressed Escape key on keyboard
  $(document).on('keyup', function(e){
    if (e.keyCode === 27) {
      if ($('#site-wrapper').hasClass('show-nav')) {
        toggleNav();
      }
    }
  });

  // Hide mobile navigation when user increases browser size
  var sizeMenuVisible = window.matchMedia("(min-width: 768px)");

  sizeMenuVisible.addListener(function(mq){
    if(mq.matches) {
      hideNav();
    }
  });

  $('ul.mob-menu li').has('ul').addClass('parentLi');
  $('ul.mob-menu li ul').addClass('sub-menu');

  $('ul.mob-menu .sub-menu').hide();

  $('ul.mob-menu .parentLi > a').on('click', function(event){
    event.preventDefault();
    $(this).toggleClass('expanded');
    $(this).parent().find('ul.sub-menu').slideToggle();
  });

/* 3. EU Cookie
------------------------------------------------------------------------------*/

  // Variables
  var expires_date = new Date('December 31, 2099 23:59:59');
  var cookie_notice_height = $('.o-cookie-bar').height();

  // Update variables and padding after screen resize
  $(window).on("debouncedresize", function( event ) {
    cookie_notice_height = $('.o-cookie-bar').height();
    if(!(Cookies.get('eu-cookie-law-goodstart') == 'accepted' || Cookies.get('eu-cookie-law-goodstart') == 'refused')) {
      $('.site-footer .wrap').css('padding-bottom', cookie_notice_height + 'px');
    }
  });

  // Show cookie notice if it's not configured
  if(Cookies.get('eu-cookie-law-goodstart') == 'accepted' || Cookies.get('eu-cookie-law-goodstart') == 'refused') {
    $('body').removeClass('o-cookie-bar--not-decided');
  }
  else {
    $('.site-footer .wrap').css('padding-bottom', cookie_notice_height + 'px');
    $('body').addClass('o-cookie-bar--not-decided');
  }

  // Hide cookie if it's accepted
  $('.js-accept-cookie').on('click', function(e){
    e.preventDefault();
    Cookies.set('eu-cookie-law-goodstart', 'accepted', { expires: expires_date });
    $('body').removeClass('o-cookie-bar--not-decided');
  });

  // Disable scripts and hide notice
  $('.js-refuse-cookie').on('click', function(e){
    e.preventDefault();
    if(theme_settings.cookie_popup == 1) {
      $.magnificPopup.open({
        items: {
            src: '<div class="white-popup">' + theme_settings.cookie_popup_text + '</div>',
            type: 'inline'
        },
        mainClass: 'mfp-with-fade',
        removalDelay: 160
      });
    }
    else {
      console.log('Tracking scripts and cookies are disabled.');
    }
    Cookies.set('eu-cookie-law-goodstart', 'refused', { expires: expires_date });
    // Opt out functions
    // doOptout();
    $('body').removeClass('o-cookie-bar--not-decided');
  });

/* 4. Dropdown Menu
------------------------------------------------------------------------------*/

  $('.primary-menu .menu-item-has-children > a').on('click', function(e){
    e.preventDefault();
    $(this).parent().toggleClass('opened');
  });

  // Hide dropdown after click outside the menu
  $(document).on('click', function(event) {
    if (!$(event.target).closest('.primary-menu .menu-item-has-children a').length) {
      $('.primary-menu .menu-item-has-children').removeClass('opened');
    }
  });

/* 5. Lightbox for images and videos
------------------------------------------------------------------------------*/

  $('a[href$=jpg], a[href$=jpeg], a[href$=jpe], a[href$=png], a[href$=gif]').magnificPopup({
    type: 'image',
    removalDelay: 500,
    mainClass: 'mfp-with-fade',
    closeOnContentClick: true,
    midClick: true
  });

  $('.popup-youtube, .popup-vimeo, .popup-gmaps').magnificPopup({
    disableOn: 700,
    type: 'iframe',
    mainClass: 'mfp-with-fade',
    removalDelay: 160,
    preloader: false,
    fixedContentPos: false,
    iframe: {
			patterns: {
        youtube: {
          index: 'youtube.com/',
          id: function(url) {
                  var m = url.match(/[\\?\\&]v=([^\\?\\&]+)/);
                  if ( !m || !m[1] ) return null;
                  return m[1];
              },
          src: '//www.youtube.com/embed/%id%?autoplay=1'
        },
				youtube_short: {
				  index: 'youtu.be/',
				  id: 'youtu.be/',
				  src: '//www.youtube.com/embed/%id%?autoplay=1' // URL that will be set as a source for iframe.
				}
			}
		}
  });

/* 6. Responsive Videos
/*******************************************************************************/

  fitvids({
    // players: 'iframe[src*="example.com"]'
  });

/* 7. Scoll Animations Init
/*******************************************************************************/

  AOS.init({
    disable: 'mobile',
    once: true
  });

  window.addEventListener('load', AOS.refresh);

  function refreshAOS() {
    AOS.refresh();
  }

  $('img').on('load', _.throttle(refreshAOS, 1000));

/* 8. Object Fit Images
/*******************************************************************************/

  objectFitImages();

/* 9. Framework Scripts
/*******************************************************************************/

//= include framework-scripts.js

/* 10. Contact Form 7 Fix
/*******************************************************************************/

  $('.wpcf7-form p:empty').remove();

/* 11. Accordion
/*******************************************************************************/

  // Hide all accordions
  var allPanels = $('.c-accordion dd').hide();
  var allTitles = $('.c-accordion dt');

  $('.c-accordion dt > a').click(function(e) {
  e.preventDefault();
  $this = $(this);
  $title = $this.parent();
  $target =  $this.parent().next();

  if(!$target.hasClass('c-accordion__text--active')){
    allPanels.removeClass('c-accordion__text--active').slideUp();
    allTitles.removeClass('c-accordion__subtitle--active');
    $target.addClass('c-accordion__text--active').slideDown();
    $title.addClass('c-accordion__subtitle--active');
  }
  else {
    allPanels.removeClass('c-accordion__text--active').slideUp();
    allTitles.removeClass('c-accordion__subtitle--active');
  }
  return false;
  });

/* 12. Map
/*******************************************************************************/

  $.each($('.c-map'), function(){

    var lat = $(this).data('lat');
    var long = $(this).data('long');
    var zoom = $(this).data('zoom');

    L.Icon.Default.imagePath = theme_settings.template_dir + '/assets/img/leaflet/';
    var mymap = new L.map($(this)[0], {
    scrollWheelZoom: false
    }).setView([lat, long], zoom);

    // create custom icon
    var pinIcon = L.icon({
        iconUrl: theme_settings.template_dir + '/assets/img/leaflet/pin.svg',
        shadowUrl:  theme_settings.template_dir + '/assets/img/leaflet/marker-shadow.png',
        iconSize: [40, 50], // size of the icon
        iconAnchor:   [16, 50],
        shadowAnchor: [10, 39],
        popupAnchor:  [5, -48]
    });

    // Open Street Map - Wikimedia
    L.tileLayer('https://maps.wikimedia.org/osm-intl/{z}/{x}/{y}{r}.png', {
        attribution: '<a href="https://wikimediafoundation.org/wiki/Maps_Terms_of_Use">Wikimedia</a>',
        maxZoom: 19
    }).addTo(mymap);

    if($(this).data('popup')) {
      L.marker([lat, long], {icon: pinIcon}).addTo(mymap)
      .bindPopup($(this).data('popup'))
      .openPopup();
    }

    // Open Street Map
    /*
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
        maxZoom: 19
    }).addTo(mymap);
    */

    // Open Street Map - Black and White
    /*
    L.tileLayer('http://{s}.tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
        maxZoom: 19
    }).addTo(mymap);
    */

    // Mapbox - remember to change accessToken
    /*
    L.tileLayer('https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token={accessToken}', {
        attribution: 'Map data © <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
        maxZoom: zoom,
        id: 'mapbox.streets',
        accessToken: 'pk.eyJ1IjoiYWRhbXBpbGNoIiwiYSI6ImNqbTY0aGlzeTB0eTEza280dGVpcjVnemMifQ.zZjr5GSWWpe4lYGBjvuz1A'
    }).addTo(mymap);
    */

    mymap.on('click', function() {
    if (mymap.scrollWheelZoom.enabled()) {
      mymap.scrollWheelZoom.disable();
      }
      else {
      mymap.scrollWheelZoom.enable();
      }
    });

  });

/* 13. Equal Heights
------------------------------------------------------------------------------*/

  // Example:
  // $('.something').matchHeight();

  // Match Height compatibility with Lazy Load techniques
  function refreshMatchHeight() {
    $.fn.matchHeight._update();
  }
  $('img').on('load', _.throttle(refreshMatchHeight, 1000));

/* 14. Smooth Scroll
------------------------------------------------------------------------------*/

  $('a[href*="#"]')
  // Remove links that don't actually link to anything
  .not('[href="#"]')
  .not('[href="#0"]')
  .click(function(event) {
    event.preventDefault();
    // Get the hash. In this example, "#myDestinationAnchor".
    var targetSelector = this.hash;
    var $target = $(targetSelector);

    // Animate the scroll to the destination...
    $('html, body').animate(
        {
            scrollTop: $target.offset().top // Scroll to this location.
        }, {
            // Set the duration long enough to allow time
            // to lazy load the elements.
            duration: 2000,

            // At each animation step, check whether the target has moved.
            step: function( now, fx ) {

                // Where is the target now located on the page?
                // i.e. its location will change as images etc. are lazy loaded
                var newOffset = $target.offset().top;

                // If where we were originally planning to scroll to is not
                // the same as the new offset (newOffset) then change where
                // the animation is scrolling to (fx.end).
                if(fx.end !== newOffset)
                    fx.end = newOffset;
            }
        }
    );
  });


/* 15. Sliders
------------------------------------------------------------------------------*/

$('.slider-people__wrapper').slick({
  infinite: true,
  slidesToShow: 5,
  slidesToScroll: 1,
  infinite: true,
  autoplay: true,
  autoplaySpeed: 3000,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,

      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
  ]
});
$('.slider-image-text__wrapper').slick({
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  infinite: true,
});




var $image = $('#image');

$image.cropper({
  aspectRatio: 1 / 1,
  crop: function(event) {
    console.log(event.detail.x);
    console.log(event.detail.y);
    console.log(event.detail.width);
    console.log(event.detail.height);
    console.log(event.detail.rotate);
    console.log(event.detail.scaleX);
    console.log(event.detail.scaleY);
  }
});

// Get the Cropper.js instance after initialized
var cropper = $image.data('cropper');



}); // end of jQuery code


/* XX. Range Inputs
------------------------------------------------------------------------------*/


var rng2 = document.querySelector('#range2');
var container = rng2.parentNode;
var values = rng2.getAttribute('value').split(',');

values.forEach(function (value, i, values) {
  var rangePart = rng2.cloneNode();
  rangePart.type = 'range';
  rangePart.removeAttribute('value');
  rangePart.value = value;
  rangePart = container.insertBefore(rangePart, rng2);
});

rng2.remove();

function range2() {
  var rngOrig = document.querySelector("[type='range'][multiple]"),
      rngOrigVal = rngOrig.value,

      rngClone = document.querySelector("[type='range'][multiple] + [type='range'][multiple]"),
      rngCloneVal = rngClone.value,

      line = document.getElementById('input-value2');

      line.style.left = (rngOrigVal) + '%';
      line.style.width = (rngCloneVal - rngOrigVal) + '%';

}
